// @flow
import { Box, Typography } from '@material-ui/core';
import React from 'react';
import styles from './Step.module.scss';

const Step = ({ index, image, title, content }) => {
  return (
    <div className={styles.container}>
      <span className={styles.connectLine}></span>
      <div className={styles.index}>
        <span>{index}</span>
      </div>
      <div className={styles.image}>
        <img src={image} alt={title} loading="lazy" />
      </div>
      <Box flex={1} fontSize={18}>
        <Typography variant="h6">{title}</Typography>
        <p>{content}</p>
      </Box>
    </div>
  );
};

export default Step;
